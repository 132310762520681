import { useEffect } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';

import {
  APP_LANGUAGE_COOKIE_NAME,
  APP_SUPPORTED_LANGUAGES,
  APP_DEFAULT_LANGUAGE,
} from '../../configs/app.config';
import {
  isPasswordFieldEnabled,
  setPasswordFieldState,
} from '../../handlers/showPasswordField/showPasswordField.handler';

export const useMainPageRedirect = (): void => {
  const router = useRouter();
  const searchParams = useSearchParams();

  useEffect(() => {
    const passwordState = searchParams.get('password');
    setPasswordFieldState(passwordState);

    const detectBrowserLanguage = () => {
      if (typeof window !== 'undefined') {
        const browserLang = navigator.language;
        return browserLang.split('-')[0];
      }

      return undefined;
    };

    const storedLanguage = localStorage.getItem(APP_LANGUAGE_COOKIE_NAME);
    const detectedLanguage = storedLanguage ?? detectBrowserLanguage();

    const languageToRedirect =
      detectedLanguage && APP_SUPPORTED_LANGUAGES.includes(detectedLanguage)
        ? detectedLanguage
        : APP_DEFAULT_LANGUAGE;

    if (isPasswordFieldEnabled()) {
      router.push(`/${languageToRedirect}/start`);
    } else {
      router.push(`/${languageToRedirect}/report`);
    }
  });
};
