'use client';

import { Suspense } from 'react';

import { Box, Grid2, CircularProgress } from '@mui/material';

import { useMainPageRedirect } from '../hooks/useMainPageRedirect/useMainPageRedirect';

const MainLoader = () => {
  useMainPageRedirect();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', pt: { xs: 2, md: 4 } }}>
      <CircularProgress />
    </Box>
  );
};

// Redirect the user to the default locale when `/` is requested
const RootPage = () => (
  <Grid2 component="main" size={12}>
    <Suspense>
      <MainLoader />
    </Suspense>
  </Grid2>
);

export default RootPage;
