import { SHOW_PASSWORD_FIELD } from '../../configs/app.config';

export const setPasswordFieldState = (passwordState: string | null): void => {
  if (!passwordState) {
    return;
  }

  sessionStorage.setItem(SHOW_PASSWORD_FIELD, passwordState);
};

export const isPasswordFieldEnabled = (): boolean =>
  sessionStorage.getItem(SHOW_PASSWORD_FIELD) !== 'off';
