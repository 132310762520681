import packageJson from '../../package.json';

export const APP_LANGUAGE_COOKIE_NAME = 'APP-LANGUAGE';

export const APP_VERSION = process.env['NEXT_PUBLIC_VERSION'] ?? '0.0.0';
export const APP_NAME = process.env['NEXT_PUBLIC_NAME'] ?? '';
export const APP_DESCRIPTION = process.env['NEXT_PUBLIC_DESCRIPTION'] ?? '';
export const APP_KEYWORDS = process.env['NEXT_PUBLIC_KEYWORDS'] ?? '';
export const APP_THEME_COLOR = process.env['NEXT_PUBLIC_THEME_COLOR'] ?? '';
export const APP_MULTI_PARTNER = process.env['NEXT_PUBLIC_MULTI_PARTNER'] === 'true';
export const APP_PARTNER_NAME = process.env['NEXT_PUBLIC_PARTNER_NAME'] ?? '';
export const APP_PARTNER_FULL_NAME = process.env['NEXT_PUBLIC_PARTNER_FULL_NAME'] ?? '';
export const APP_PARTNER_ID = process.env['NEXT_PUBLIC_PARTNER_ID'] ?? '';
export const APP_PARTNER_MAIL_TEMPLATE_ID =
  process.env['NEXT_PUBLIC_PARTNER_MAIL_TEMPLATE_ID'] ?? '';
export const APP_PARTNER_LOGOTYPE_URL = process.env['NEXT_PUBLIC_PARTNER_LOGOTYPE_URL'] ?? '';
export const APP_PARTNER_FAVICON_URL = process.env['NEXT_PUBLIC_PARTNER_FAVICON_URL'] ?? '';
export const APP_PARTNER_PRIVACY_PAGE_URL =
  process.env['NEXT_PUBLIC_PARTNER_PRIVACY_PAGE_URL'] ?? '';
export const APP_ENABLE_ANONYMOUS_REPORT =
  process.env['NEXT_PUBLIC_ENABLE_ANONYMOUS_REPORT'] === 'true';
export const APP_DEFAULT_LANGUAGE = process.env['NEXT_PUBLIC_DEFAULT_LANGUAGE'] ?? 'pl';
export const APP_SUPPORTED_LANGUAGES = process.env['NEXT_PUBLIC_SUPPORTED_LANGUAGES']?.split(
  ',',
) ?? ['pl', 'en'];

export const APP_PARTNER_SIGNALLER_REGULATION =
  process.env['NEXT_PUBLIC_PARTNER_SIGNALLER_REGULATION'] ?? '';
export const APP_PARTNER_SIGNALLER_PERSONAL_DATA_REGULATION =
  process.env['NEXT_PUBLIC_PARTNER_SIGNALLER_PERSONAL_DATA_REGULATION'] ?? '';
export const APP_PARTNER_SIGNALLER_DATA_PROTECTION_REGULATION =
  process.env['NEXT_PUBLIC_PARTNER_SIGNALLER_DATA_PROTECTION_REGULATION'] ?? '';
export const APP_PARTNER_SIGNALLER_REGULATION_ST =
  process.env['NEXT_PUBLIC_PARTNER_SIGNALLER_REGULATION_ST'] ?? '';

export const ST_WEB_CRYPTOGRAPHY_VERSION = packageJson.dependencies[
  '@safeandtrust/general-app-web-cryptography'
]?.replaceAll('^', '');

/**
 * SessionStorage key where we keep the information about the need to display the leave modal
 *
 * **After navigate key should be cleared**
 */
export const LEAVE_ALERT_SS_KEY = encodeURI(`${APP_NAME}-refererHref`);

/**
 * SessionStorage key where will be saved user token
 */
export const USER_TOKEN_SS_KEY = encodeURI(`${APP_NAME}-token`);
/**
 *  SessionStorage key where will be saved refresh token
 */
export const REFRESH_TOKEN_SS_KEY = encodeURI(`${APP_NAME}-refreshToken`);
/**
 * SessionStorage key where will be saved user token expiration time
 */
export const USER_EXPIRATION_TIME_SS_KEY = encodeURI(`${APP_NAME}-expirationToken`);

/**
 * SessionStorage key where will be saved chosen partner id by user
 */
export const CHOSEN_PARTNER_ID = encodeURI(`${APP_NAME}-chosenPartnerId`);

/**
 * SessionStorage key where will be saved chosen partner name by user
 */
export const CHOSEN_PARTNER_NAME = encodeURI(`${APP_NAME}-chosenPartnerName`);

/**
 * SessionStorage key where will be saved chosen partner full name by user
 */
export const CHOSEN_PARTNER_FULL_NAME = encodeURI(`${APP_NAME}-chosenPartnerFullName`);

/**
 * SessionStorage key where will be saved information if password field should be hidden
 */
export const SHOW_PASSWORD_FIELD = encodeURI(`${APP_NAME}-passwordField`);

/**
 * Enable devtools - only dev
 */
export const ENABLE_DEV_TOOLS =
  process.env.NODE_ENV === 'development' && process.env['NEXT_PUBLIC_ENABLE_DEV_TOOLS'] === 'true';
